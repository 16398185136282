import { Container } from "@util/standard";
import React from "react";
import { Maybe, SanityPartner } from "../../../graphql-types";
import Img from "gatsby-image";
import ContentBlock from "../shared/sub/ContentBlock";
import styled from "styled-components";

const StyledA = styled.a<{ width?: string }>`
  margin: auto;
  border: 1px solid #d3d3d3;
  padding: 10px;
  width: ${props => props.width || "fit-content"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.125rem 0.5rem 0.125rem rgba(0, 0, 0, 0.04);

  p {
    text-align: center;
    font-family: bold;
    font-size: 22px;
    color: #222;
    margin-top: 16px;
    max-width: 80%;
  }
`;

interface Props {
  partner: SanityPartner;
  partnerTier?: Maybe<string> | undefined;
  index?: number;
  totalCount?: number;
}

const PartnerPreviewCard = (props: Props) => {
  const { partner, partnerTier, index, totalCount } = props;

  if (partner == null) return null;

  const imageSize = partnerTier == "top" ? 200 : 150;

  return (
    <>
      <Container
        flexDirection="column"
        width="100%"
        justifyContent="space-between"
        isMobileColumn
      >
        <Container width="100%" isMobileColumn>
          <StyledA href={partner.link} target="_blank" width="100%">
            <Img
              fluid={partner.logo?.asset?.fluid}
              style={{ width: imageSize, height: imageSize }}
              imgStyle={{ objectFit: "contain" }}
            />
            <p>{partner.title}</p>
          </StyledA>
        </Container>
      </Container>
    </>
  );
};

export default PartnerPreviewCard;
