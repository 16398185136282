import { usePartnerSectionsQuery } from "@query";
import React from "react";
import { Container, H2 } from "@util/standard";
import { Maybe, SanityPartnershipSections } from "../../../graphql-types";
import PartnerPreviewCard from "./partnerCard";
import styled from "styled-components";

const PartnershipSections = () => {
  const allSanityPartnershipSections = usePartnerSectionsQuery();

  return (
    <Container
      flexDirection="column"
      width="70%"
      margin="auto"
      mobilePadding="0px 5%"
      isMobileColumn
    >
      {allSanityPartnershipSections.nodes.map(
        (partnershipSection: SanityPartnershipSections) => {
          console.log({ partnershipSection });

          return (
            <Container
              width="100%"
              flexDirection="column"
              key={partnershipSection._id}
              margin="0px auto 50px auto"
            >
              <H2 width="100%" textAlign="center" fontSize={28}>
                {partnershipSection.title}
              </H2>

              <StyledPartnersGrid partnerTier={partnershipSection.partnerLevel}>
                {partnershipSection.partners &&
                  partnershipSection.partners.map((partner, index) => {
                    if (partner == null) return null;
                    return (
                      <PartnerPreviewCard
                        partner={partner}
                        partnerTier={partnershipSection.partnerLevel}
                        key={partner._id}
                        index={index}
                        totalCount={partnershipSection?.partners?.length}
                      />
                    );
                  })}
              </StyledPartnersGrid>
            </Container>
          );
        }
      )}
    </Container>
  );
};

export default PartnershipSections;

const StyledPartnersGrid = styled.div<{
  partnerTier?: Maybe<string> | undefined;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  ${props =>
    props.partnerTier === "mid" && `grid-template-columns: 1fr 1fr 1fr`};

  ${props =>
    props.partnerTier === "bottom" && `grid-template-columns: 1fr 1fr 1fr 1fr`};

  @media only screen and (max-width: 1200px) {
    ${props => props.partnerTier === "mid" && `grid-template-columns: 1fr 1fr`};

    ${props =>
      props.partnerTier === "bottom" && `grid-template-columns: 1fr 1fr 1fr`};
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
