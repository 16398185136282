import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Hero from "../components/shared/Hero";
import ContentBlock from "../components/shared/sub/ContentBlock";

import ImpactBlock from "../components/shared/ImpactBlock";
import DonateBlock from "../components/shared/DonateBlock";

import SEO from "../components/shared/Seo";
import PartnershipSections from "@components/partnership/partnershipSections";

export default function Partnership() {
  const { sanityPartnershipPage } = useStaticQuery(graphql`
    {
      sanityPartnershipPage {
        titleBlock {
          _key
          _type
          _rawContent
        }
        titleBlockLink {
          linkText
          url
        }
        partnershipHeroBlock {
          heroText
          heroImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
              title
            }
          }
        }
        seoBlock {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const {
    titleBlock,
    titleBlockLink,
    partnershipHeroBlock,
  } = sanityPartnershipPage;
  const { contentLink, _rawContent } = titleBlock;

  return (
    <div>
      <SEO seoData={sanityPartnershipPage?.seoBlock} slug="partnership" />
      <Hero
        heroText={partnershipHeroBlock.heroText}
        heroImage={partnershipHeroBlock.heroImage}
        blockVisible
        whiteText
      />
      <ContentBlock blocks={_rawContent} links={titleBlockLink} />
      {/* <InfoBlock /> */}
      <PartnershipSections />
      {/* <Partners /> */}
      <ImpactBlock />
      <DonateBlock />
    </div>
  );
}
